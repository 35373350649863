import { useMemo, useState } from 'react';
import { useDebounce } from './misc.ts';

export function useLocationAutocompletePredictions() {
	const autocompleteService = useMemo(
		() => new google.maps.places.AutocompleteService(),
		[],
	);

	const [predictions, setPredictions] = useState<
		google.maps.places.AutocompletePrediction[]
	>([]);
	const resetPredictions = () => setPredictions([]);
	const fetchPredictions = useDebounce((input: string) => {
		if (!input) {
			resetPredictions();
			return;
		}

		autocompleteService.getPlacePredictions(
			{
				input,
				language: 'en',
				componentRestrictions: { country: 'us' },
			},
			predictions => {
				setPredictions(predictions ?? []);
			},
		);
	}, 400);

	return {
		predictions,
		fetchPredictions,
		resetPredictions,
	};
}

export function useLocationAutocompletePlaces() {
	const placesService = useMemo(
		() => new google.maps.places.PlacesService(document.createElement('div')),
		[],
	);

	const fetchPlace = (placeId: string) =>
		new Promise<google.maps.places.PlaceResult | null>(res =>
			placesService.getDetails({ placeId }, res),
		);

	return { fetchPlace };
}

export function useAutocompleteArrowsInput({
	predictions,
	handlePredictionSelected,
}: {
	predictions: google.maps.places.AutocompletePrediction[];
	handlePredictionSelected: (
		prediction: google.maps.places.AutocompletePrediction,
	) => void;
}) {
	const [selected, setSelected] = useState<number | null>(null);
	const selectNext = () => {
		if (selected === null) {
			setSelected(0);
			return;
		}
		setSelected((selected + 1) % predictions.length);
	};
	const selectPrev = () => {
		if (selected === null || selected === 0) {
			setSelected(predictions.length - 1);
			return;
		}
		setSelected(Math.max((selected - 1) % predictions.length, 0));
	};

	const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const key = e.key;
		if (key !== 'ArrowDown' && key !== 'ArrowUp' && key !== 'Enter') {
			return;
		}

		e.preventDefault();
		switch (key) {
			case 'ArrowDown':
				selectNext();
				break;
			case 'ArrowUp':
				selectPrev();
				break;
			case 'Enter':
				if (selected === null) {
					return;
				}
				const prediction = predictions[selected];
				if (!prediction) {
					return;
				}
				handlePredictionSelected(prediction);
				break;
		}
	};

	return { selected, setSelected, autocompleteOnKey: handleKey };
}
